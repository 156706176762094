var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  checkEmail: () => checkEmail,
  debounce: () => debounce,
  decode: () => decode,
  distinctArray: () => distinctArray,
  encode: () => encode,
  getCookieSSR: () => getCookieSSR,
  priceFormatter: () => priceFormatter,
  removeWhiteSpaces: () => removeWhiteSpaces,
  sanitize: () => sanitize,
  slugify: () => slugify
});
module.exports = __toCommonJS(src_exports);

// src/slugify/index.ts
function slugify(text) {
  if (text === null || text === void 0)
    return "";
  return text.toString().toLowerCase().normalize("NFKD").replace(/\s+/g, "-").replace(/[^\w\-]+/g, "").replace(/\-\-+/g, "-");
}

// src/distinct-array/index.ts
function distinctArray(departments, key) {
  const uniqueItems = /* @__PURE__ */ new Map();
  for (const item of departments) {
    const value = item[key];
    if (!uniqueItems.has(value))
      uniqueItems.set(value, item);
  }
  return Array.from(uniqueItems.values());
}

// src/encode-decode/index.ts
var import_node_buffer = require("buffer");
function encode(str) {
  return import_node_buffer.Buffer.from(str).toString("hex");
}
function decode(str) {
  return import_node_buffer.Buffer.from(str, "hex").toString("utf8");
}

// src/price-formatter/index.ts
function priceFormatter(value) {
  if (typeof value !== "number" || isNaN(value))
    return "R$0,00";
  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2
  }).format(value).replace(/\s/g, " ");
}

// src/sanitize-strings/index.ts
function sanitize(string) {
  if (!string)
    return "";
  const charactersToSanitize = [
    {
      regex: /[àáâã]/g,
      replace: "a"
    },
    {
      regex: /[èéê]/g,
      replace: "e"
    },
    {
      regex: /[íì]/g,
      replace: "i"
    },
    {
      regex: /[óòôõ]/g,
      replace: "o"
    },
    {
      regex: /[úùü]/g,
      replace: "u"
    },
    {
      regex: /[ç]/g,
      replace: "c"
    },
    {
      regex: /[ñ]/gi,
      replace: "n"
    },
    {
      regex: /[^a-z0-9]/gi,
      replace: ""
    }
  ];
  string = string.toLowerCase();
  charactersToSanitize.forEach((sanitize2) => {
    string = string.replace(sanitize2.regex, sanitize2.replace);
  });
  return string;
}
function removeWhiteSpaces(value) {
  return value.replace(/ /g, "");
}

// src/check-email/index.ts
function checkEmail(value) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value);
}

// src/get-cookies-ssr/index.ts
function getCookieSSR(req, cookieName) {
  const cookies = req.headers.cookie ? req.headers.cookie.split("; ") : [];
  const parsedCookies = cookies.reduce((acc, cookie2) => {
    const [name, value] = cookie2.split("=");
    acc[name] = decodeURIComponent(value);
    return acc;
  }, {});
  const cookie = parsedCookies[cookieName];
  return cookie;
}

// src/debounce/index.ts
function debounce(func, wait) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  checkEmail,
  debounce,
  decode,
  distinctArray,
  encode,
  getCookieSSR,
  priceFormatter,
  removeWhiteSpaces,
  sanitize,
  slugify
});
