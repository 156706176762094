export function deliveryPolicies({
  postalCode,
  channel,
  subchannel,
  orderNumber,
  origin,
}) {
  return `
  query {
    deliveryPolicies(
      postalCode: "${postalCode}",
      channel: "${channel}",
      subchannel: "${subchannel}",
      orderNumber: "${orderNumber}",
      origin: "${origin}")
    {
      title
      freeShippingMessages
      freeShippingWarning
    }
    getAddressByPostalCode(postalCode: "${postalCode}"){
      city
      state
      stateShort
      code
      street
      neighborhood
    }
  }
`
}
