import { getCookie } from '@petlove/lion/libs/cookies'
import { deliveryPolicies } from '../../queries/delivery/deliveryPolicies'

export async function fetchDeliveryPolicies({
  endpoint,
  postalCode,
  channel,
  subchannel,
}) {
  try {
    const fingerprint = getCookie('PL_fingerprint') || ''
    const orderNumber = getCookie('PL_orderNumber') || ''
    const origin = getCookie('ADVANTAGE_NETWORK') || 'petlove'

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        fingerprint,
        'order-number': orderNumber,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: deliveryPolicies({
          postalCode,
          channel,
          subchannel,
          orderNumber,
          origin,
        }),
      }),
    })

    const data = await response.json() || {}
    return data
  }
  catch (error) {
    return error
  }
}
