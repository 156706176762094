import { defineStore } from 'pinia';

export const useAddressStore = defineStore('address', {
	state: () => ({
		addresses: [],
	}),
	actions: {
		async fetchAddresses() {
			const data = await $fetch('service/addresses');
			this.setAddresses(data);

			return data;
		},
		setAddresses(data) {
			this.addresses = data;
		},
	},
	getters: {
		getAddresses() {
			return this.addresses;
		},
	},
});
