// import { webviewDispatchEvent } from '@petlove/lion/libs/analytics/webview'
// import { useUiStore } from '@petlove/lion/store/ui/index'

export const customEvent = (
	event_category = '',
	event_action = '',
	event_label = '',
	event_noninteraction = 0,
) => {
	// const uiStore = useUiStore();
	// const { webView } = uiStore;

	// if (webView) {
	// 	return webviewDispatchEvent(custom_event, {
	// 		event_category,
	// 		event_action,
	// 		event_label,
	// 		event_noninteraction,
	// 		event_value,
	// 		value
	// 	});
	// }

	try {
		window.dataLayer?.push({
			event: 'custom_event',
			event_category,
			event_action,
			event_label,
			event_noninteraction,
			event_value: 0,
			value: 0.0,
		});
	}
	catch (e) {
		console.error(`Error sending data to GA: ${e}`);
	}
};
