import { defineStore } from 'pinia';

import { getCookie, setCookie } from '@petlove/lion/libs/cookies';
import { distinctArray } from '@petlove/frontend-utilities-utils';

import { useAuthStore } from '@petlove/lion/store/auth';
import { useCartStore } from '@petlove/lion/store/cart';

export const useUiStore = defineStore('ui', {
	state: () => ({
		businessType: 'petlove',
		showDiscountBanner: false,
		showNewsletterModal: false,
		features: {
			subscription: true,
			petProfile: true,
			categoryGalleries: true,
			breedsGallery: true,
			tips: true,
			newsletter: true,
			whatsappContact: false,
		},
		banners: {},
		brands: [],
		breeds: {},
		contents: [],
		menus: [],
		newMenu: [],
		sellingPoints: [],
		dogHeroServices: [],
		webView: false,
		loadingSingleSignOn: false,
		showBlackHeader: false,
	}),
	getters: {
		getBusinessType() {
			return this.businessType;
		},

		mustShowDiscountBanner() {
			return this.showDiscountBanner;
		},

		mustShowNewsletterModal() {
			return this.showNewsletterModal;
		},

		showSubscription() {
			return this.features.subscription;
		},

		showPetProfile() {
			return this.features.petProfile;
		},

		showCategoryGalleries() {
			return this.features.categoryGalleries;
		},

		showBreedsGallery() {
			return this.features.breedsGallery;
		},

		showTips() {
			return this.features.tips;
		},

		showNewsletter() {
			return this.features.newsletter;
		},

		showWhatsappButton() {
			return this.features.whatsappContact;
		},

		getBanners() {
			return this.banners;
		},

		getSideBanner() {
			return this.banners.side;
		},

		getBrands() {
			return this.brands;
		},

		getBreeds() {
			return this.breeds;
		},

		getContents() {
			return this.contents;
		},

		getSellingPoints() {
			return this.sellingPoints;
		},

		getMobileMenus() {
			const clearDepartments = distinctArray(this.menus, 'name');
			const dogs = clearDepartments.find(item => item.name === 'Cachorro');
			const cats = clearDepartments.find(item => item.name === 'Gatos');
			const brands = clearDepartments.find(item => item.name === 'Marcas');
			const breeds = clearDepartments.find(item => item.name === 'Raças');

			return { dogs, cats, brands, breeds };
		},

		getDesktopMenus() {
			const clearDepartments = distinctArray(this.menus, 'name');

			const departmentsRaw = [
				'Cachorro',
				'Gatos',
				'Clube de Descontos',
				'Marcas',
			];

			const departments = departmentsRaw

			const another_pets = clearDepartments.filter(
				item =>
					!departments.includes(item.name)
					&& !departmentsRaw.includes(item.name),
			);

			const main_pets = clearDepartments.filter(item =>
				departments.includes(item.name),
			);

			const menus = main_pets;
			menus.splice(3, 0, {
				name: 'Outros pets',
				children: another_pets,
				desktop_banner: {
					image:
            'https://www.petlove.com.br/static/uploads/images/menu/outros.jpg',
					bg_color: '#EFEFEF',
				},
			});

			return menus;
		},

		getNewMenu() {
			return this.newMenu;
		},

		getBannerBoxes() {
			return {
				dog: this.banners?.boxes?.cachorro,
				cat: this.banners?.boxes?.gatos,
			};
		},

		getWebView() {
			return this.webView;
		},

		getFeatures() {
			return this.features;
		},

		getDogHeroServices() {
			return this.dogHeroServices;
		},

		getLoadingSingleSignOn() {
			return this.loadingSingleSignOn;
		},
		getShowBlackHeader() {
			return this.showBlackHeader;
		},
	},
	actions: {
		setFeaturesCategoryGalleries(value) {
			this.features.categoryGalleries = value;
		},
		setFeaturesTips(value) {
			this.features.tips = value;
		},
		setFeaturesNewsletter(value) {
			this.features.newsletter = value;
		},
		setFeaturesWhatsappContact(value) {
			this.features.whatsappContact = value;
		},
		setShowDiscountBanner(value) {
			this.showDiscountBanner = value;
		},
		setShowNewsletterModal(showModal) {
			this.showNewsletterModal = showModal;
		},
		setBanners(banners) {
			this.banners = banners;
		},
		setBrands(brands) {
			this.brands = brands;
		},
		setBreeds(breeds) {
			this.breeds = breeds;
		},
		setContents(contents) {
			this.contents = contents;
		},
		setSellingPoints(sellingPoints) {
			this.sellingPoints = sellingPoints;
		},
		setMenus(menus) {
			this.menus = menus;
		},
		setWebView(webView) {
			this.webView = webView;
		},
		setDogHeroServices(dogHeroServices) {
			this.dogHeroServices = dogHeroServices;
		},
		setLoadingSingleSignOn(loading) {
			this.loadingSingleSignOn = loading;
		},
		showDiscountBanner() {
			const cartStore = useCartStore();
			const discountApplied = cartStore.getWelcomeDiscount;
			
			!discountApplied && this.showDiscountBanner();
		},
		setFirstViewCookie() {
			const firstViewCookie = getCookie('PL_firstView');
			// Do nothing when is not first view
			if (firstViewCookie === 'false') return;
			// Set first view when cookie doesnt exist
			if (!firstViewCookie) {
				setCookie('PL_firstView', 'true');
				return;
			}
			// Or set to false
			setCookie('PL_firstView', 'false');
		},
		showNewsletterModal() {
			const authStore = useAuthStore();
			const isNewClient = authStore.isNewClient;

			if (!isNewClient) {
				return;
			}

			const newsletterCookie = getCookie('PL_lightboxNewsletter');
			const firstViewCookie = getCookie('PL_firstView');

			if (!(newsletterCookie || firstViewCookie === 'true')) {
				this.showNewsletterModal(true);
				setCookie('PL_lightboxNewsletter', true);
			}
		},
	},
});
