/**
 *  docs: https://developers.google.com/tag-manager/ecommerce-ga4?hl=pt-br#measure_promotion_viewsimpressions
 * **/

/**
 * @typedef PromotionDataEventGA4
 * @property {string} promotion_id
 * @property {string} promotion_name
 * @property {string} creative_name
 * @property {string} creative_slot
 * @property {string} index
 * @property {string} banner_type
 * @property {string} supplier
 */

/**
 * @param {('view'|'click')} type
 * @param {PromotionDataEventGA4[]} promotions
 * @param {string} position
 */
export const dispatchPromotionsGA4 = (type, promotions, position) => {
  try {
    const event = {
      view: 'view_promotion',
      click: 'select_promotion',
    }[type];
    const nd = 'nao-disponivel';

    window.dataLayer?.push({
      event,
      ecommerce: {
        items: promotions.map((promo) => {
          return {
            promotion_id: promo.id || nd,
            promotion_name: promo.name || nd,
            creative_name: promo.creative || nd,
            creative_slot: String(promo.position) || String(position) || nd,
            index: promo.position || position || nd,
            banner_type: promo.banner_type || nd,
            supplier: promo.supplier || nd,
          };
        }),
      },
    });
  }
  catch (e) {
    console.error(`Error sending data to GA4: ${e}`);
  }
};
