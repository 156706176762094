<script setup lang="ts">
import type { NuxtError } from 'nuxt/app';

import ErrorIlustration from '@petlove/lion/components/ErrorIlustration/index.vue'

const { isDesktop } = useDevice();

const layout = isDesktop ? 'desktop' : 'mobile';

const props = defineProps({
  error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: '/' });

console.error(props.error);
</script>

<template>
  <NuxtLayout
    :name="layout"
  >
    <section class="flex flex-column align-items-center justify-content-center my-10">
      <h1 class="font-title-s font-bold color-neutral-dark mb-4">
        AH NÃO!
      </h1>
      <p class="color-neutral-dark text-center">
        Parece que um dos nossos pets escondeu a página que você procurava :(
      </p>
      <p class="color-neutral-dark py-3 text-center">
        Verifique se você digitou o endereço corretamente. Enquanto isso, estamos procurando onde <br> nosso bichinho escondeu...
      </p>
      <ErrorIlustration class="mb-10" />

      <CaButton @click="handleError">
        Voltar para a página inicial
      </CaButton>
    </section>
  </NuxtLayout>
</template>
