import { defineStore } from 'pinia';

import {
	setCookie,
	getCookie,
	getUtmCookies,
	getLocalStorageKey,
	getLastLocalStorageKey,
	COOKIE_ORDER_NUMBER,
	COOKIE_ZIPCODE,
	COOKIE_EMAIL,
	COOKIE_PENDING_COUPON,
	COOKIE_ORDER_TOKEN,
	COOKIE_CART,
	COOKIE_ADVANTAGE_NETWORK,
	COOKIE_ADVANTAGE_NETWORK_MEDIUM,
} from '@petlove/lion/libs/cookies';

export const useCartStore = defineStore('cart', {
	state: () => ({
		welcomeDiscount: '',
		bullet: 0,
		cartInfo: {},
		order: null,
		loading: false,
	}),
	getters: {
		getWelcomeDiscount() {
			return this.welcomeDiscount
				? this.welcomeDiscount
				: getCookie('PL_pendingCoupon');
		},
		getBullet() {
			return this.bullet;
		},
		getCartInfo() {
			return this.cartInfo;
		},
		getOrder() {
			return this.order;
		},
		isLoading() {
			return this.loading;
		},
		getLocalStorageVariants() {
			const variants = JSON.parse(
				window?.localStorage?.getItem(getLocalStorageKey()) || '[]',
			);
			return variants?.map(variant => ({
				id: variant.id,
				sku: variant.sku,
				quantity: variant.quantity,
			}));
		},
	},
	actions: {
		setWelcomeDiscount(payload) {
			setCookie('PL_welcomePromotionClicked', true);
			setCookie('PL_pendingCoupon', payload);
			this.welcomeDiscount = payload;
		},
		setBullet(value) {
			this.bullet = value;
		},
		cartInfo(value = {}) {
			this.cartInfo = value;
		},
		setCartOrder(value) {
			this.order = value;
		},
		setLoading(value) {
			this.loading = value;
		},
		getDiscount() {
			const coupon = getCookie('PL_pendingCoupon');
			coupon && this.setWelcomeDiscount(coupon);
		},

		async syncBullet() {
			const cartCookie = getCookie(COOKIE_CART);
			if (cartCookie) {
				this.setBullet(JSON.parse(cartCookie).totalItems);
			}
		},

		async addVariantToCart({ variants = [], subscription = {}, orderInfos = {} }) {
			const { email, number, token, utms, postal_code } = _getLocalInfo();
			const localVariants = this.getLocalStorageVariants;
			const data = {
				order: {
				line_items_attributes: [...variants, ...localVariants],
				email,
				...orderInfos,
				...utms
				},
				number,
				postal_code,
				subscription,
				token
			};

			this.cartInfo = data;
			this.clearLocalStorageVariants();
		},

		async upsertCart() {
			try {
				this.loading = true;
				const fingerprint = getCookie('PL_fingerprint');
				const { public: { baseURL } } = useRuntimeConfig();
				const { advantage_network, advantage_network_medium } = _getLocalInfo();
				const headers = {
					fingerprint,
					token: this.cartInfo?.token,
				};

				if (advantage_network_medium === 'lojas_online' && advantage_network) {
					headers['Whitelabel-Subdomain'] = advantage_network;
				  }

				const response = await $fetch('/v1/cart/upsert', {
					baseURL,
					method: 'PUT',
					headers,
					body: this.cartInfo,
				});
			
				this.setCartOrder(response);
				const { number, token } = response;
				const products = _setCartProducts(response);
				const cart = _setCart(response, products);
				_setLocalOrder({ number, token });
				_setCartCookie(cart);
				this.syncBullet();
				return response;
			}
			catch (error) {
				return error;
			}
			finally {
				this.loading = false;
			}
		},

		async setOrder() {
			const { token, number } = _getLocalInfo();

			try {
				if (token && number) {
					const response = await useLabradorFetch(`/v1/cart/${number}`, {
						headers: { 'order-token': token },
					});
					this.setCartOrder(response);
					return response;
				}
			}
			catch (error) {
				console.log(error);
			}
		},

		createOrder(order) {
			this.setCartOrder(order);
			const { number, token } = order;
			_setLocalOrder({ number, token });
		},

		clearLocalStorageVariants() {
			window?.localStorage?.removeItem(getLocalStorageKey());
			window?.localStorage?.removeItem(getLastLocalStorageKey());
		},
	},
});

const _getLocalInfo = () => {
	return {
		advantage_network: getCookie(COOKIE_ADVANTAGE_NETWORK) || '',
    	advantage_network_medium: getCookie(COOKIE_ADVANTAGE_NETWORK_MEDIUM) || '',
		email: getCookie(COOKIE_EMAIL) || '',
		coupon: getCookie(COOKIE_PENDING_COUPON) || '',
		postal_code: getCookie(COOKIE_ZIPCODE)?.replace(/[-]/g, '') || '',
		number: getCookie(COOKIE_ORDER_NUMBER) || '',
		token: getCookie(COOKIE_ORDER_TOKEN) || '',
		utms: getUtmCookies() || {},
	};
};

const _setLocalOrder = ({ number = '', token = '' }) => {
	setCookie(COOKIE_ORDER_NUMBER, number);
	setCookie(COOKIE_ORDER_TOKEN, token);
};

const _setCartProducts = (order = {}) => {
	return order.line_items
		?.filter(p => !p.promotional_item && p.price > 0.2)
		.reduce(
			(acc, item, index) => {
				const shouldHavePipe = index !== 0 ? '|' : '';
				const brands
          = (!acc.brands.includes(item.brand)
          	&& acc.brands + shouldHavePipe + item.brand)
         	|| acc.brands;

				const categories
          = (!acc.categories.includes(item.category)
          	&& acc.categories + shouldHavePipe + item.category)
         	|| acc.categories;
				return {
					brands,
					categories,
				};
			},
			{
				brands: '',
				categories: '',
			},
		);
};

const _setCartCookie = (cart) => {
	if (cart) {
		cart = JSON.stringify(cart);
		setCookie(COOKIE_CART, cart);
	}
};

const _setCart = (order, products) => {
	return {
		totalItems: order.total_quantity,
		amount: order.total_with_credit,
		products,
	};
};
