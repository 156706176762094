import { defineStore } from 'pinia';

import { setCookie, getCookie, COOKIE_AUTOCOMPLETE_ZIPCODE, COOKIE_SHOW_AUTOCOMPLETE_INFO } from '@petlove/lion/libs/cookies';
import { fetchDeliveryPolicies } from '@petlove/lion/graphql/requests/delivery';
import { fetchZipcodeFromIPAddress } from '@petlove/lion/graphql/requests/location';

export const useShipmentStore = defineStore('shipment', {
	state: () => ({
		shipment: null,
		freeShipment: null,
		selectedZipcode: null,
		showModal: false,
		autocompleteZipcode: false,
		autocompleteInfo: false
	}),
	actions: {
		isAutocompleteZipcode() {
			return this.autocompleteZipcode || getCookie(COOKIE_AUTOCOMPLETE_ZIPCODE)
		},
		isAutocompleteInfo() {
			return this.autocompleteInfo || getCookie(COOKIE_SHOW_AUTOCOMPLETE_INFO)
		},
		setShipment(data) {
			this.shipment = data;
		},
		setShowModal(data) {
			this.showModal = data;
		},
		setFreeShipment(data) {
			this.freeShipment = data;
		},
		setSelectedZipcode(data) {
			setCookie('PL_zipcode', data);
			this.selectedZipcode = data;
		},
		setAutocompleteZipcode(data) {
			setCookie(COOKIE_AUTOCOMPLETE_ZIPCODE, data);
			this.autocompleteZipcode = String(data);
		},
		setShowAutocompleteInfo(data) {
			setCookie(COOKIE_SHOW_AUTOCOMPLETE_INFO, data);
			this.autocompleteInfo = String(data);
		},
		async fetchIpLocation() {
			try {
				const { public : { apiGwURL }} = useRuntimeConfig();

				const endpoint = window.location.host.includes('local') ? '/api-gw' : apiGwURL;

				const data = await fetchZipcodeFromIPAddress(endpoint);
				
				const zipcode = data?.ipDetails?.postalcode?.long_text || '';

				if (zipcode?.length) {
					this.setSelectedZipcode(zipcode);
					this.setAutocompleteZipcode(true);
					this.setShowAutocompleteInfo(true);
				}
			}
			catch (error) {
				this.$nuxt?.$honeybadger?.notify(error, 'Error on fetchIpLocation');
			}
		},
		async calculateShipment(payload) {
			const { postalCode, sku, fingerprint, orderNumber } = payload;

			if (!postalCode) return Promise.reject('Parameters missing.');

			const {
				data: {
					delivery_options: { dynamic_messages, out_of_stock },
					getAddressByPostalCode: { city },
				},
			} = await this.$fetchShipment({
				postalCode:
          typeof postalCode === 'string' ? postalCode.replace(/-/g, '') : postalCode,
				sku: typeof sku === 'string' ? sku.split(':')[0] : sku,
				fingerprint,
				orderNumber,
			});

			const shipmentObject = {
				stockAvailableForZipcode: !out_of_stock,
				deliveryOptions: dynamic_messages,
				city,
			};

			this.setShipment(shipmentObject);
		},
		async checkFreeShippingRules(payload) {
			try {
				let { postalCode } = payload;
				const { isMobile } = useDevice();
				const { apiGwURL } = useRuntimeConfig().public;

				if (!postalCode) return Promise.reject('Postal code parameter missing.');

				if (typeof postalCode === 'string') {
					postalCode = postalCode.replace(/-/g, '');
				}

				const channel = 'website';
				const subchannel = isMobile ? 'mobile' : 'desktop';

				if (!apiGwURL) {
					throw new Error('Missing API gateway endpoint env');
				}

				const endpoint = window.location.host.includes('local') ? '/api-gw' : apiGwURL;

				const response = await fetchDeliveryPolicies({ endpoint, postalCode, channel, subchannel });

				return response;
			}
			catch (e) {
				console.error('Failed to verify delivery rules', e);
			}
		},

		async freeShipmentModal() {
			const data = await useLabradorFetch('v1/shipment-rules');

			this.setFreeShipment(data);
			return data;
		},
	},
});
