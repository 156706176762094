var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  validateCpf: () => validateCpf,
  validateEmail: () => validateEmail,
  validateName: () => validateName
});
module.exports = __toCommonJS(src_exports);

// src/email/index.ts
var EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function validateEmail(value) {
  return EMAIL_REGEX.test(value);
}

// src/name/index.ts
var NAME_REGEX = /^[a-zA-ZÀ-ú ]*$/;
function validateName(value) {
  if (value.length < 3)
    return false;
  return NAME_REGEX.test(value);
}

// src/cpf/index.ts
function verifierCpfDigit(numbers) {
  const numbersStr = numbers.split("").map((number) => {
    return parseInt(number, 10);
  }).join("");
  const modulus = numbersStr.length + 1;
  const multiplied = numbersStr.split("").map((number, index) => {
    return parseInt(number, 10) * (modulus - index);
  });
  const mod = multiplied.reduce((buffer, number) => {
    return buffer + number;
  }) % 11;
  return mod < 2 ? 0 : 11 - mod;
}
function strip(number) {
  return (number || "").toString().replace(/[^\d]/g, "");
}
function validateCpf(number) {
  const stripped = strip(number);
  const BLACKLIST = [
    "00000000000",
    "11111111111",
    "22222222222",
    "33333333333",
    "44444444444",
    "55555555555",
    "66666666666",
    "77777777777",
    "88888888888",
    "99999999999",
    "12345678909"
  ];
  if (!stripped)
    return false;
  if (stripped.length !== 11)
    return false;
  if (BLACKLIST.includes(stripped))
    return false;
  let numbers = stripped.substring(0, 9);
  numbers += verifierCpfDigit(numbers);
  numbers += verifierCpfDigit(numbers);
  return numbers.substring(-2) === stripped.substring(-2);
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  validateCpf,
  validateEmail,
  validateName
});
