import { defineStore } from 'pinia';

import { useAuthStore } from '@petlove/lion/store/auth';

export const useUsersStore = defineStore('users', {
	state: () => ({
		userPublic: {
			complete_orders: 0,
			confirmed: false,
			eligible_subscriber: false,
			has_voucher: false,
			new_client: false,
			public_id: '',
			subscriber: false,
			subscriber_since: null,
			email: '',
		},
		userPets: [],
		userPublicIsLoaded: false,
	}),
	getters: {
		getUserPublic() {
			return this.userPublic;
		},
		getUserFirstPet() {
			return this.userPets?.[0] || {};
		},
		subscriber() {
			return this.userPublic.subscriber;
		},
		isClubMember() {
			return this.userPublic.discount_club_member ?? false;
		},
		getUserPublicIsLoaded() {
			return this.userPublicIsLoaded;
		},
	},
	actions: {
		setUserPublic(data) {
			this.userPublic = data;
			this.userPublicIsLoaded = true;

			if (import.meta.browser) {
				window.infoPetlove = { client: data };
			}
		},
		async fetchUserPublic(baseURL) {
			try {
				const authStore = useAuthStore();
				const userToken = authStore.getUserToken;
				const { email } = authStore.getSession;

				if (!email || !userToken) return;

				const data = await $fetch(`/v1/users/${email}`, {
					baseURL,
					headers: {
						'X-Spree-Token': userToken,
					},
				});

				this.setUserPublic(data);
			}
			catch (error) {
				console.error(error);
			}
		},
		async fetchUserPets(baseURL) {
			try {
				const authStore = useAuthStore();
				const userToken = authStore.getUserToken;

				if (!userToken) return;

				const data = await $fetch('/v1/pets', {
					baseURL,
					headers: {
						'X-Spree-Token': userToken,
					},
				});

				this.userPets = data.pets || [];
			}
			catch (error) {
				console.error(error);
			}
		},
	},
});
