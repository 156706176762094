import{ zipcodeFromIPAddressQuery } from '../../queries/location/index';


export const fetchZipcodeFromIPAddress = async (endpoint) => {
  const res = await fetch(endpoint, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: zipcodeFromIPAddressQuery()
    })
  });
  const { data } = (await res.json()) || {};
  return data;
};
