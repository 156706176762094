<template>
	<div :id="`${id}-observer`">
		<slot :show="show" />
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		threshold: {
			type: Number,
			default: 0.5,
		},
	},
	data() {
		return {
			show: false,
			observer: null
		};
	},
	beforeUnmount() {
		this.observer?.disconnect();
	},
	mounted() {
		this.setIntersectionObserver();
	},
	methods: {
		setIntersectionObserver() {
			// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
			if (!window.IntersectionObserver) {
				this.show = true;
				this.$emit('isVisible', true);
				return;
			}
			const options = {
				threshold: this.threshold,
			};
			const callback = (entries) => {
				entries.forEach((entry) => {
					if (entry?.isIntersecting) {
						this.show = true;
						this.$emit('isVisible', true);
						this.observer?.unobserve(entry.target);
					}
				});
			};
			this.observer = new IntersectionObserver(callback, options);
			const element = document.getElementById(`${this.id}-observer`);
			if (element) this.observer?.observe(element);
		},
	},
};
</script>
