export const productDetailsEvent = (products = []) => {
	try {
		const formattedProducts = products.map(product =>
			_formatProduct(product),
		);
		window.dataLayer?.push({
			event: 'productDetails',
			ecommerce: {
				detail: {
					products: formattedProducts,
				},
			},
		});
	}
	catch (e) {
		console.error(`Error sending data to GA: ${e}`);
	}
};

export const addToCartEvent = (products = [], subscription, list) => {
	try {
		const formattedProducts = products.map((product) => {
			const formattedProduct = _formatProduct(product);
			formattedProduct.list = list;
			formattedProduct.quantity = product.quantity || 1;
			formattedProduct.dimension27 = !!subscription.enabled;
			formattedProduct.dimension28
        = product.subscription_behavior === 'default';
			formattedProduct.dimension34
        = product.subscription_behavior === 'place_order_now';
			return formattedProduct;
		});
		window.dataLayer?.push({
			event: 'addToCart',
			add_type: list,
			ecommerce: {
				currencyCode: 'BRL',
				add: {
					products: formattedProducts,
				},
			},
		});
	}
	catch (e) {
		console.error(`Error sending data to GA: ${e}`);
	}
};

export const productClickEvent = (products, list) => {
	const formattedProducts = products.map((product) => {
		const formattedProduct = _formatProduct(product);
		formattedProduct.position = product.position || 'nao-disponivel';
		return formattedProduct;
	});

	window.dataLayer?.push({
		event: 'ProductClick',
		ecommerce: {
			click: {
				actionField: { list },
				products: formattedProducts,
			},
		},
	});
};

function _formatProduct(product = {}) {
	const formattedCategories = _formatCategories(product);
	const formattedProduct = {
		...formattedCategories,
		name: product.name,
		id: product.sku,
		price: product.price,
		metric4: product.subscriber_price || product.subscriberPrice,
		brand: product.brand?.name || product.brand || 'nao-disponivel',
		exclusive_brand: product.exclusive_brand,
		position: product.position || 'nao-disponivel',
		out_of_stock:
      typeof product.inStock === 'boolean' ? !product.inStock : !product.in_stock,
		dimension33: product.primeStock ?? product.prime_stock,
		variant: product.shortName || product.short_name || 'nao-disponivel',
	};
	return formattedProduct;
}

const _formatCategories = ({ categories }) => {
	const department = categories?.[0]?.name ?? categories?.[0];
	const category = categories?.[1]?.name ?? categories?.[1];
	const subcategory = categories?.[2]?.name ?? categories?.[2];

	return {
		category: `${department} / ${category} / ${subcategory}`,
		dimension29: department,
		dimension30: category,
	};
};

export const productImpressionsEvent = (products = [], list = '') => {
	if (!Array.isArray(products) || !products.length) return;

	try {
		if (products.length > 10) {
			// GA accepts only 10 products per event
			const chunks = _sliceIntoChunks(products, 10);
			return chunks.forEach((products, index) =>
				formatAndSendEvent(products, index),
			);
		}
		return formatAndSendEvent(products);
	}
	catch (e) {
		console.error(`Error sending data to GA: ${e}`);
	}
	function formatAndSendEvent(products, chunksIndex = 0) {
		const formattedProducts = products.map((product, index) => {
			const formattedProduct = _formatProduct(product);
			formattedProduct.list = list;
			formattedProduct.position
        = product.position || chunksIndex * 10 + (index + 1);
			return formattedProduct;
		});

		window.dataLayer?.push({
			event: 'productImpressions',
			ecommerce: {
				currencyCode: 'BRL',
				impressions: formattedProducts,
			},
		});
	}
};

const _sliceIntoChunks = (list, chunkSize) => {
	const res = [];
	for (let i = 0; i < list.length; i += chunkSize) {
		const chunk = list.slice(i, i + chunkSize);
		res.push(chunk);
	}
	return res;
};
