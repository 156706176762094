<template>
	<CaModal
		:title="
			hasZipcodeSelected ? 'Regras de frete grátis' : 'Informe sua localizacão'
		"
		:show="show"
		:btn-loading="loading"
		:aux-btn-loading="loading"
		:aux-btn-label="showMobileButton && hasZipcodeSelected ? 'Alterar CEP' : ''"
		:btn-label="textMobileButton"
		@close="onClose"
		@btn-click="showMobileAndNotLogginAndZipcode ? handleZipcode() : onClose()"
		@aux-btn-click="
			hasZipcodeSelected ? changeSelectedZipcode() : handleZipcode()
		"
	>
		<CaLoading
			v-if="loading"
			v-cloak
		/>
		<div
			v-else
			v-cloak
		>
			<p
				v-if="!hasZipcodeSelected"
				v-cloak
				class="font-body color-neutral mt-5 mb-5"
			>
				Escolha onde quer receber e veja informações sobre as regras de frete na
				sua região.
			</p>
			<div
				v-if="hasLoggedIn && !selectedZipcode"
				v-cloak
			>
				<b class="color-neutral font-body-s font-bold">
					Selecione um endereço:
				</b>
				<form
					v-if="addresses && addresses.length"
					v-cloak
					class="mt-6"
				>
					<CaRadio
						v-for="address in addresses"
						:key="address.id"
						v-model="selectedZipcode"
						type="radio"
						name="radio"
						:value="address.displayZipcode"
					>
						<span class="font-body color-neutral-darkest">
							{{ address.label }}
						</span>
						<br>
						<span class="font-body-s color-neutral">
							{{ address.city }}/{{ address.state }} -
							{{ address.displayZipcode }}</span>
					</CaRadio>
					<b
						v-if="hasLoggedIn && isMobile"
						v-cloak
						class="color-neutral font-body-s font-bold"
					>
						Ou informe outro CEP:
					</b>
				</form>
			</div>
			<div
				v-if="hasZipcodeSelected && deliveryRulesInfo"
				v-cloak
			>
				<span
					v-if="isMobile"
					class="color-neutral font-body font-bold"
				>
					Endereço
				</span>
				<CaCard
					outline
					class="mt-4 mb-4"
				>
					<div>
						<div class="flex flex-column">
							<span
								v-if="!isMobile"
								class="color-neutral font-body font-bold"
							>
								Endereço
							</span>
							<div class="flex justify-content-between align-items-center">
								<div class="flex flex-column mt-3">
									<span class="font-body color-neutral-darkest">
										{{ deliveryRulesInfo.getAddressByPostalCode.street }}
									</span>
									<span class="font-body-s color-neutral">
										{{ deliveryRulesInfo.getAddressByPostalCode.city }}/{{
											deliveryRulesInfo.getAddressByPostalCode.state
										}}
										-
										{{ selectedZipcode }}
									</span>
								</div>
								<CaButton
									v-if="!isMobile"
									class="button-small"
									type="secondary"
									size="small"
									@click="changeSelectedZipcode()"
								>
									Alterar CEP
								</CaButton>
							</div>
						</div>
					</div>
				</CaCard>
				<span
					v-if="isMobile"
					class="color-neutral font-body-s font-bold"
				>
					Como funciona:
				</span>
				<CaCard
					v-if="deliveryRulesInfo.deliveryPolicies"
					outline
				>
					<span
						v-if="!isMobile"
						class="color-neutral font-body-s font-bold"
					>
						Como funciona:
					</span>
					<div class="mt-4">
						<ul :class="!isMobile && 'ml-6'">
							<li
								v-for="(rule, index) in deliveryRulesInfo.deliveryPolicies
									.freeShippingMessages"
								:key="index"
								class="font-body color-neutral-darkest"
							>
								{{ rule }}
							</li>
						</ul>
						<div class="font-body-s mt-2 color-neutral-main">
							* Em alguns casos, o frete pode ser cobrado dependendo da
							composição dos itens do pedido e da região de entrega.
							<span class="color-primary text-underline font-normal">
								<a
									target="_blank"
									href="https://atendimento.petlove.com.br/hc/pt-br/articles/360007106834-Pol%C3%ADtica-de-frete-gr%C3%A1tis"
								>
									Saiba mais
								</a>
							</span>
						</div>
					</div>
				</CaCard>
				<CaAlert
					v-if="hasFreeShippingWarning"
					v-cloak
					type="alert"
					class="mt-4"
				>
					{{ deliveryRulesInfo.deliveryPolicies.freeShippingWarning }}
				</CaAlert>
				<CaDivisor
					class="mt-4"
					color="neutral-medium"
				/>
				<CaAlert class="mt-4">
					<ul class="ml-4">
						<li class="font-body-xs">
							O cálculo para frete grátis é realizado após os descontos
							aplicados no carrinho.
						</li>
						<li class="font-body-xs">
							Esta política de frete não se aplica às entregas rápidas "Petlove
							Já".
						</li>
					</ul>
				</CaAlert>
			</div>
		</div>

		<div
			v-if="!hasZipcodeSelected"
			v-cloak
			class="flex justify-content-between mt-3 mb-3"
		>
			<CaInputText
				v-model="zipcode"
				class="mr-3 pt-3"
				label="Informe seu CEP"
				mask="#####-###"
			/>
			<span
				v-if="hasLoggedIn && isMobile"
				v-cloak
				class="span-small"
			>
				<CaButton
					full-width
					@click="handleZipcode()"
				>
					{{ hasZipcodeSelected ? 'Alterar CEP' : 'Usar' }}
				</CaButton>
			</span>
			<span
				v-else-if="!isMobile"
				v-cloak
				class="span-small"
			>
				<CaButton
					full-width
					@click="handleZipcode()"
				>
					{{ hasZipcodeSelected ? 'Alterar CEP' : 'Usar' }}
				</CaButton>
			</span>
			<span
				v-if="!isMobile"
				class="span-small ml-3"
			>
				<a
					target="_blank"
					href="https://buscacepinter.correios.com.br/app/endereco/index.php"
				>
					<CaButton
						full-width
						type="secondary"
					> Não sei meu CEP </CaButton>
				</a>
			</span>
		</div>
	</CaModal>
</template>

<script>
import { getCookie } from '@petlove/lion/libs/cookies';
import { mapGetters, mapActions } from 'pinia';
import { useAuthStore } from '@petlove/lion/store/auth';
import { useShipmentStore } from '@petlove/lion/store/shipment';

export default {
	name: 'ModalFrequency',
	props: {
		show: Boolean,
	},
	data() {
		return {
			isMobile: false,
			addresses: [],
			loading: false,
			selectedZipcode: getCookie('PL_zipcode') || '',
			zipcode: '',
			deliveryRulesInfo: false,
		};
	},
	watch: {
		async show(isOpened) {
			this.isMobile = this.$device?.isMobile;
			if (isOpened) {
				this.selectedZipcode = getCookie('PL_zipcode') || '';

				this.loading = true;
				if (this.hasLoggedIn && !this.hasZipcodeSelected) {
					try {
						this.addresses = await this.fetchAddresses();
					}
					catch (e) {
						console.log('Error fetch addresses', e);
					}
				}
				else if (this.hasZipcodeSelected) {
					await this.verifyDeliveryRules();
				}
				this.loading = false;
			}
		},
		async selectedZipcode(postalCode) {
			if (postalCode?.length > 7) {
				this.loading = true;
				await this.verifyDeliveryRules();
				this.loading = false;
				this.setSelectedZipcode(postalCode);
			}
		},
	},
	async mounted() {
		this.isMobile = this.$device?.isMobile;
	},
	computed: {
		...mapGetters(useAuthStore, ['isLogged']),
		textMobileButton() {
			if (this.showMobileAndHasZipcode) return 'Entendi';
			else if (this.showMobileAndNotLogginAndZipcode) return 'Usar CEP';

			return '';
		},
		showMobileAndHasZipcode() {
			return this.showMobileButton && this.hasZipcodeSelected;
		},
		showMobileAndNotLogginAndZipcode() {
			return (
				this.showMobileButton && !this.hasZipcodeSelected && !this.hasLoggedIn
			);
		},
		showMobileButton() {
			return this.isMobile && !this.loading;
		},
		hasZipcodeSelected() {
			return !!this.selectedZipcode?.length;
		},
		hasLoggedIn() {
			return !!getCookie('PL_email');
		},
		hasFreeShippingWarning() {
			return !!this.deliveryRulesInfo?.deliveryPolicies?.freeShippingWarning;
		},
	},
	methods: {
		...mapActions(useShipmentStore, [
			'setSelectedZipcode',
			'checkFreeShippingRules',
		]),
		...mapActions(useAddressStore, ['fetchAddresses']),

		async verifyDeliveryRules() {
			const postalCode = this.selectedZipcode;

			const { data } = await this.checkFreeShippingRules({
				postalCode,
			});

			this.deliveryRulesInfo = data;
		},
		onClose() {
			this.selectedZipcode = '';
			this.$emit('handle-show-modal', false);
		},
		handleZipcode() {
			this.selectedZipcode = this.zipcode;
			this.setSelectedZipcode(this.selectedZipcode);
		},
		async changeSelectedZipcode() {
			this.loading = true;

			if (this.hasLoggedIn) this.addresses = await this.fetchAddresses();

			this.loading = false;
			this.selectedZipcode = '';
		},
	},
};
</script>

<style lang="scss" scoped>
li {
  list-style-type: disc;
}

.span-small {
  max-width: 170px;
  width: 100%;
}

.button-small {
  margin: 0;
  width: 110px;
}
</style>
