<template>
  <div class="wrapper">
    <transition name="fade">
      <div v-if="loadingDy" class="loading">
        <CaLoading />
      </div>
      <div v-else>
        <CaScroll :show-dots="false" :spacing="6" :show-controllers="isDesktop">
          <template #title>
            <CaTitle
              tag="h2"
              :size="$device.isDesktop ? 'm' : 's'"
              class="font-bold title-black text-center px-5"
              color="primary"
              datatest-id="title"
            >
              {{ title }}
            </CaTitle>
          </template>
          <div v-for="(product, index) in products" :key="product.sku" class="product-card-wrapper">
            <WsObserveVisibility :id="product.sku" @is-visible="sendProductImpressionsEvent(product, index)">
              <CaProductCard
                :product="product"
                show-product-variant
                show-add-to-cart
                :pin-add-to-cart="!isDesktop"
                :add-to-cart-loading="loading"
                :add-to-cart-disabled="loading"
                :is-club-ab-test-active="isClubAbTestActive"
                @add-cart="addToCart(product, index)"
                @click-card="shelfClickHandler(product, index)"
                />
            </WsObserveVisibility>
          </div>
        </CaScroll>
      </div>
    </transition>
  </div>
</template>

<script setup>
import {
  productClickEvent,
  productImpressionsEvent,
  addToCartEvent
} from "@petlove/lion/libs/analytics/events/ecommerceEvents";
import { mParticleClick, mParticleAddToCart } from "@petlove/lion/libs/mparticle";
import { priceFormatter } from "@petlove/frontend-utilities-utils";

const props = defineProps({
  experiment: {
    type: String,
    default: "",
    required: true,
  },
  pageType: {
    type: String,
    default: "",
    required: true,
  },
  sku: {
    type: String,
    default: "",
  },
  buyOption: Boolean,
  clickButtonOption: Boolean,
  loading: {
    type: Boolean,
    default: false,
  },
  args: {
    type: Object,
    default: () => ({}),
  },
  isClubAbTestActive: {
    type: Boolean,
    default: false,
  },
  customAttributes: {
    type: Object,
    default: () => ({}),
  },
  listName: {
    type: String,
    default: "",
  }
});

const emit = defineEmits(["add-to-cart", "click-button"]);

const { isDesktop } = useDevice();
const dyRecommendations = ref({});
const loadingDy = ref(true);

const { reportDYSlotClick, getDYChoose } = useDynamicYield();

const title = computed(() => dyRecommendations.value?.custom?.title || "");
const products = computed(() => {
  const mappedVariation = dyRecommendations.value?.slots?.map((variation) => {
    const product = variation.productData;
    const hasBestDiscount = parseFloat(product.discount_percentage || 0) >= 10;
    const subscriberPrice = hasBestDiscount
      ? product.dy_display_price
      : product.subscriber_price;

    return {
      sku: variation.sku,
      ...product,
      slotId: variation.slotId,
      displayPrice: priceFormatter(parseFloat(product.list_price)),
      subscriberPrice: priceFormatter(parseFloat(subscriberPrice)),
    };
  });

  return mappedVariation || [];
});

onMounted(() => {
  getDynamicYieldRecommendation();
});


async function getDynamicYieldRecommendation() {
  const { $honeybadger } = useNuxtApp();

  const payload = {
    experiment: props.experiment,
    pageType: props.pageType,
    sku: props.sku ?? "",
    args: {
      [props.experiment]: props.args,
    },
    customAttributes: props.customAttributes,
  };

  try {
    const data = await getDYChoose(payload);
    dyRecommendations.value =
      data?.choices?.[0]?.variations?.[0]?.payload?.data ?? {};
    if (!dyRecommendations.value?.slots)
      throw new Error("Empty DY recommendations");
  } catch (error) {
    $honeybadger?.notify(error, {
      name: "fetchDyRecommendations",
      context: { payload },
    });
  } finally {
    loadingDy.value = false;
  }
}


function addToCart(product, index) {
  product.position = index + 1;
  reportDYSlotClick(product.slotId)
  addToCartEvent([product], {}, `${props.pageType} | ${props.experiment}`);
  mParticleAddToCart([product]);
  emit("add-to-cart", product);
}

async function shelfClickHandler(product, index) {
  try {
    product.position = index + 1;
    await sendProductClickEvents(product);
  } catch (error) {
    console.log(error);
  } finally {
    window.location.assign(`/${product.slug}/p?sku=${product.sku}`);
  }
}

async function sendProductClickEvents(product) {
  const promises = [
    reportDYSlotClick(product.slotId),
    productClickEvent([product], props.experiment),
    mParticleClick([product], props.experiment),
  ];
  await Promise.all(promises);
}

function sendProductImpressionsEvent(product, index) {
  product.position = index + 1;
  productImpressionsEvent([product], props.listName);
}
</script>

<style lang="scss" scoped>
.product-card-wrapper {
  width: 177px;
}

.wrapper {
  position: relative;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
