export const getCookie = (cname) => {
	if (typeof window !== 'undefined') {
		const name = cname + '=';
		const ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				const cookie = c.substring(name.length, c.length);
				const decodedCookie = decodeURIComponent(cookie);
				return decodedCookie;
			}
		}
		return '';
	}
};

export const setCookieWithExpire = (cname, cvalue, expireDays) => {
	const HOURS_PER_DAY = 24;
	const MINUTES_PER_HOUR = 60;
	const SECONDS_PER_MINUTE = 60;
	const MILLISECONDS_PER_SECOND = 1000;

	const expireCookie = new Date(
		new Date().getTime()
			+ expireDays
			* HOURS_PER_DAY
			* SECONDS_PER_MINUTE
			* MINUTES_PER_HOUR
			* MILLISECONDS_PER_SECOND,
	).toUTCString();
	if (typeof window !== 'undefined') {
		document.cookie = cname + '=' + cvalue + ';path=/;' + `expires=${expireCookie}`;
	}
};

export const setCookie = (cname, _cvalue, exdays, minutes) => {
	if (typeof window !== 'undefined') {
		let cvalue = JSON.stringify(_cvalue);
		cvalue = JSON.parse(cvalue);

		const d = new Date();
		let time = exdays * 24 * 60 * 60 * 1000;

		if (minutes) {
			time = minutes * 60 * 1000;
		}

		d.setTime(d.getTime() + time);

		const expires = 'expires=' + d.toUTCString();
		cvalue = encodeURIComponent(cvalue);

		document.cookie = cname + '=' + cvalue + ';path=/;' + expires;
	}
};

export const getUtmCookies = () => {
	const utmCookie = getCookie('IPS_checkout');
	return utmCookie ? JSON.parse(utmCookie) : null;
};

export const getLocalStorageKey = () => window?.DONATION_CART_KEY || 'PL_local_variants';
export const getLastLocalStorageKey = () => window?.DONATION_LAST_VARIANT_KEY || 'PL_local_last_variant_updated';

const getSearchListParams = (searchParams = {}) => {
	return Object.keys(searchParams).map(i => `${i}=${searchParams[i]}`);
};

const utmsList = [
	'utm_medium',
	'utm_source',
	'utm_campaign',
	'click_id',
	'gclid',
];

const setCoupon = (param, campaignParams) => {
	param = param.split('=');
	const key = param[0];
	const value = param[1];

	const validUtm = utmsList.indexOf(key) >= 0;

	if (value && validUtm) {
		campaignParams[key] = decodeURIComponent(value);
	}
	else if (value && key == 'coupon') {
		setCookie(COOKIE_PENDING_COUPON, value);
	}

	return campaignParams;
};

export const setUtms = (queryParams = {}) => {
	let campaignParams = {};
	const params = getSearchListParams(queryParams);

	params.forEach((i) => {
		campaignParams = setCoupon(i, campaignParams);
	});

	const hasCampaignParams = Object.keys(campaignParams).length;

	if (hasCampaignParams) {
		if (campaignParams.gclid) {
			campaignParams.utm_source = 'google';
			campaignParams.utm_medium = 'cpc';
			campaignParams.utm_campaign = null;

			const partnerUTM = getCookie('ADVANTAGE_NETWORK');
			setCookieWithExpire('ADVANTAGE_NETWORK', partnerUTM, -1);
		}

		campaignParams = JSON.stringify(campaignParams);
		setCookie(COOKIE_UTM_PROMOTION, campaignParams, 30);
	}
};

export const deleteCookie = (cname) => {
	setCookie(cname, '', -10);
};

export const COOKIE_CART = 'PL_cart';
export const COOKIE_PENDING_COUPON = 'PL_pendingCoupon';
export const COOKIE_ZIPCODE = 'PL_zipcode';
export const COOKIE_ORDER_NUMBER = 'PL_orderNumber';
export const COOKIE_ORDER_TOKEN = 'PL_orderTokenId';
export const COOKIE_EMAIL = 'PL_email';
export const COOKIE_UTM_PROMOTION = 'IPS_checkout';
export const COOKIE_SUBSCRIBER = 'PL_isSubscriber';
export const COOKIE_PUBLIC_ID = 'PL_publicId';
export const COOKIE_X_CLIENT_SESSION_DEVICE = 'x-client-session-device';
export const COOKIE_CLIENT_SESSION_KEY = 'x-client-session';
export const COOKIE_FINGERPRINT = 'PL_fingerprint';
export const COOKIE_DELIVERY_DATE = 'PL_delivery_date';
export const COOKIE_TOUR_MODAL = 'PL_repet_tour_modal';
export const COOKIE_AUTOCOMPLETE_ZIPCODE = 'PL_isAutocompleteZipcode';
export const COOKIE_SHOW_AUTOCOMPLETE_INFO = 'PL_showAutocompleteInfo';
export const COOKIE_SHOW_AUTOCOMPLETE = 'PL_showAbAutocomplete';
export const COOKIE_ADVANTAGE_NETWORK ='ADVANTAGE_NETWORK';
export const COOKIE_ADVANTAGE_NETWORK_MEDIUM = 'ADVANTAGE_NETWORK_MEDIUM';
