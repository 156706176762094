import {
	getCookie,
	COOKIE_PUBLIC_ID,
	COOKIE_X_CLIENT_SESSION_DEVICE,
} from '@petlove/lion/libs/cookies';

let mParticle = { eCommerce: {}, ProductActionType: {} };
let catalogQueryId;
let webstore;
let eventSourceURL;

export const mParticleCustomEvent = (name, type = '', customAttrs = {}) => {
	if (!type || !_isReady()) return;

	return mParticle.logEvent(name, mParticle.EventType[type], {
		...customAttrs,
		..._setIdCustomAttributes(),
	});
};

export const mParticleLogout = () => {
	if (!_isReady()) return;

	const email = getCookie('PL_email') || '';
	const customerid = getCookie('PL_publicId') || '';

	if (email) {
		mParticleCustomEvent('logout', 'Social', {
			email,
			customerid,
			webstore: 'petlove',
		});
	}

	mParticle.Identity.logout({});
};

export const subscriptionEvent = (eventName, item) => {
	const parsedItem = {
		item_id: item?.sku || item?.id,
		item_name: item?.name,
		price: item?.price,
		quantity: Number(item?.quantity) || 1,
		item_brand: item?.brand?.name || item?.brand || '',
		item_category:
      item?.category?.name
      || item?.category
      || item?.categories?.[0]?.name
      || '',
		source: item?.source || '',
	};
	mParticleCustomEvent(eventName, 'Other', parsedItem);
};

export const mParticleAddToCart = (products = []) => {
	if (!products.length || !_isReady()) return;

	const productsPayload = products.map((product) => {
		return createProducts(_getProductObject(product, true));
	});

	const productsFiltered = _removeProductsWithoutPrice(productsPayload);
	const customAttributes = {
		query_id: catalogQueryId,
		webstore,
		..._setIdCustomAttributes(),
	};
	const customFlags = { 'Facebook.EventSourceUrl': eventSourceURL };

	if (productsFiltered.length)
		mParticle.eCommerce.logProductAction(
			mParticle.ProductActionType.AddToCart,
			productsFiltered,
			customAttributes,
			customFlags,
		);
};

const createProducts = ({
	name,
	sku,
	price,
	quantity,
	variant,
	category,
	brand,
	coupon,
	position,
	customAttributes,
}) => {
	return mParticle.eCommerce.createProduct(
		name,
		sku && sku.split(':')[0], // Prevent sending combo sku
		price,
		quantity,
		variant,
		category,
		brand,
		position,
		coupon,
		customAttributes,
	);
};

export const mParticleAddToWishList = (products = []) => {
	if (!products.length || !_isReady()) return;

	const productsPayload = products.map((product) => {
		return createProducts(_getProductObject(product, true));
	});

	const customAttributes = { webstore, ..._setIdCustomAttributes() };
	const customFlags = { 'Facebook.EventSourceUrl': eventSourceURL };

	mParticle.eCommerce.logProductAction(
		mParticle.ProductActionType.AddToWishlist,
		productsPayload,
		customAttributes,
		customFlags,
	);
};

export const mParticleViewDetail = (products = [], query) => {
	if (!products.length || !_isReady()) return;

	const productsPayload = products.map(product => createProducts(_getProductObject(product, true)));

	const firstProduct = products[0] || {};
	const { sku, name, brand, categories = [] } = firstProduct;
	const category = categories.reduce((acc, category) => `${acc} > ${category?.name || ''}`, '');

	const validSubfamilies = ['Rações', 'Caixa de Areia e Limpeza'];

	const setor = categories[1]?.name || '';
	const subfamilia = validSubfamilies.includes(categories[1]?.name) ? categories[2]?.name || '' : '';

	const customAttributes = {
		query_id: catalogQueryId,
		webstore,
		sku,
		name,
		brand: brand?.name || '',
		category,
		setor,
		subfamilia,
		source: query?.utm_source || '',
		..._setIdCustomAttributes(),
	};

	const customFlags = { 'Facebook.EventSourceUrl': eventSourceURL };

	mParticle.eCommerce.logProductAction(
		mParticle.ProductActionType.ViewDetail,
		productsPayload,
		customAttributes,
		customFlags,
	);
};

export const mParticleClick = (
	products = [],
	origin = '',
	order = {},
	catalogQueryId = '',
) => {
	if (!products.length || !_isReady()) return;

	const productsPayload = products.map((product) => {
		return createProducts(_getProductObject(product, true));
	});

	const customAttributes = {
		query_id: catalogQueryId,
		webstore,
		origin,
		..._setIdCustomAttributes(),
	};

	const transactionAttributes = {
		Id: order?.number,
		Revenue: order?.total,
	};

	mParticle.eCommerce.logProductAction(
		mParticle.ProductActionType.Click,
		productsPayload,
		customAttributes,
		{},
		transactionAttributes,
	);
};

export const mParticleLogin = (user) => {
	if (!_isReady()) return;

	const { email, public_id, publicId } = user;
	const customerid = publicId || public_id || null;

	const identityRequest = {
		userIdentities: {
			customerid,
		},
	};

	if (email) {
		_submitLoginOrSignupEvent(user);

		const isBrazeInitialized = window?.braze?.initialize();
		if (isBrazeInitialized) {
			window?.braze?.changeUser(customerid);
		}
	}
	mParticle.Identity.login(identityRequest);
};

const _getProductObject = (product, hasCustomAttributes) => {
	const customAttributes = hasCustomAttributes
		? {
				subscriber_price: product.subscriberPrice || product.subscriber_price,
			}
		: {};

	return {
		name: product.name,
		sku: product.sku,
		price: product.price,
		quantity: product.quantity || 1,
		variant: product.originalShortName || product.short_name,
		category:
      product.category?.name
      || product.category
      || product.categories?.[0]?.name
      || product.categories?.[0],
		brand: product.brand?.name || product.brand,
		position: product.position,
		coupon: undefined,
		customAttributes,
	};
};

function _isReady() {
	if (typeof window !== 'undefined') {
		mParticle = window.mParticle;
		catalogQueryId = window.localStorage?.getItem('algoliaSearchQueryId') || '';
		webstore = window.webstore?.webstore_label || '';
		eventSourceURL = window.location.href;
		return !!mParticle?.ready;
	}

	return false;
}

function _setIdCustomAttributes() {
	return {
		customerid: getCookie(COOKIE_PUBLIC_ID) || '',
		sessionid: getCookie(COOKIE_X_CLIENT_SESSION_DEVICE) || '',
	};
}

function _removeProductsWithoutPrice(products) {
	return products.filter((product) => {
		return product.Price;
	});
}

function _submitLoginOrSignupEvent(user) {
	const { email, public_id, publicId, new_record, source } = user;
	const customerid = publicId || public_id;

	const event = new_record ? 'signup' : 'login';

	mParticleCustomEvent(event, 'Social', {
		email,
		customerid,
		facebook: source == 'facebook' ? email : '',
		google: source == 'google_api' ? email : '',
		webstore,
	});
}

export const mParticleSubscription = (eventName, item = {}) => {
	if (!eventName || !_isReady()) return;

	const payload = { ...item };

	const subscriptionStepData = {};

	const eventPayload = {
		...subscriptionStepData,
		...payload,
		webstore,
		..._setIdCustomAttributes(),
	};

	mParticleCustomEvent(eventName, 'Other', eventPayload);
};
