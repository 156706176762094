const title = name =>
	name
		? `${name} | Produtos para Cachorro - Gato e outros pets`
		: 'Petlove: o maior petshop online do Brasil';

const description = description =>
	description
	|| 'Produtos para Cães, Gatos e Outros Pets você encontra aqui na Petlove! São mais de 15 mil itens para o seu Pet com os melhores preços. Parcele em até 3x sem juros.';

// Whitelabels Must Not Have Canonical
const canonical = (routePath = '') => ({
	rel: 'canonical',
	href: 'https://www.petlove.com.br' + routePath,
});

const manifest = {
	rel: 'manifest',
	href: '/manifest.json',
	crossorigin: 'use-credentials',
};

const supported_media = (device = 'mobile') => {
	const screenSize = {
		mobile: 'max-width: 640px',
		desktop: 'min-width: 640px',
	};
	return {
		name: 'supported-media',
		content: `only screen and (${screenSize[device]})`,
	};
};

const metatags = (data = {}) => {
	return [
		{
			hid: 'apple-mobile-web-app-title',
			name: 'apple-mobile-web-app-title',
			content: data.name || 'Petlove',
		},
		{
			hid: 'description',
			name: 'description',
			content:
        data.description
        || 'Produtos para Cães, Gatos e Outros Pets você encontra aqui na Petlove! São mais de 15 mil itens para o seu Pet com os melhores preços. Parcele em até 3x sem juros.',
		},
		{
			hid: 'theme-color',
			name: 'theme-color',
			content: data.theme || '#4E2096',
		},
		...open_graph(data),
	];
};

const open_graph = (data = {}) => {
	return [
		{
			name: 'fb:app_id',
			content: '211265535746847',
		},
		{
			hid: 'og:title',
			property: 'og:title',
			content: data.name
				? `${data.name} - Loja online`
				: 'Petlove: o maior petshop online do Brasil',
		},
		{
			hid: 'og:url',
			property: 'og:url',
			content: data.url || 'http://www.petlove.com.br/',
		},
		{
			hid: 'og:site_name',
			property: 'og:site_name',
			content: data.name || 'Petlove',
		},
		{
			hid: 'og:description',
			property: 'og:description',
			content:
        data.description
        || 'Produtos para Cães, Gatos e Outros Pets você encontra aqui na Petlove! São mais de 15 mil itens para o seu Pet com os melhores preços. Parcele em até 3x sem juros.',
		},
		{
			hid: 'og:type',
			property: 'og:type',
			content: 'website',
		},
		{
			hid: 'og:image',
			property: 'og:image',
			content:
        data.image
        || 'https://www.petlove.com.br/static/uploads/banner_image/image/44490/petlove_logo.png',
		},
	];
};

const link_icons = async (icons = false) => {
	const favIcons = ['16x16', '32x32'];
	const androidIcons = ['48x48', '72x72', '144x144', '192x192'];
	const appleIcons = [
		'57x57',
		'60x60',
		'72x72',
		'76x76',
		'114x114',
		'120x120',
		'144x144',
		'152x152',
		'180x180',
	];

	const [_favIcons, _androidIcons, _appleIcons] = await Promise.all([
		favIcons.map(icon => ({
			rel: 'icon',
			type: 'image/png',
			sizes: icon,
			href:
        icons[icon]
        || `https://www.petlove.com.br/static/uploads/images/pwa/favicon-${icon}.png`,
		})),
		androidIcons.map(icon => ({
			rel: 'icon',
			type: 'image/png',
			sizes: icon,
			href:
        icons[icon]
        || `https://www.petlove.com.br/static/uploads/images/pwa/android-icon-${icon}.png`,
		})),
		appleIcons.map(icon => ({
			rel: 'apple-touch-icon',
			type: 'image/png',
			sizes: icon,
			href:
        icons[icon]
        || `https://www.petlove.com.br/static/uploads/images/pwa/apple-icon-${icon}.png`,
		})),
	]);

	const _iconsList = [..._favIcons, ..._androidIcons, ..._appleIcons];

	return _iconsList.concat({
		rel: 'icon',
		type: 'image/x-icon',
		href:
			icons.favicon
			|| 'https://www.petlove.com.br/static/uploads/images/favicon.ico',
	});
};

export const schemaCorporation = {
	'@context': 'https://schema.org',
	'@type': 'Corporation',
	'name': 'Petsupermarket Comércio de Produtos para Animais S.A',
	'url': 'https://www.petlove.com.br/',
	'logo': 'https://www.petlove.com.br/static/uploads/banner_image/image/44490/petlove_logo.png',
	'sameAs': [
		'https://www.facebook.com/PetloveBR/',
		'https://twitter.com/petlovebr',
		'https://www.instagram.com/petlovebrasil/',
		'https://www.youtube.com/user/petlovebr',
	],
};

export const structuredData = {
	'@context': 'https://schema.org',
	'@type': 'WebSite',
	'url': 'https://www.petlove.com.br',
	'sameAs': [
		'https://www.facebook.com/PetloveBR',
		'https://www.youtube.com/user/PetloveBR',
		'https://twitter.com/petlovebr',
		'https://plus.google.com/u/1/+PetLoveBrasil',
		'https://www.instagram.com/petlovebrasil/',
	],
	'potentialAction': {
		'@type': 'SearchAction',
		'target': 'https://www.petlove.com.br/busca?q={search_term_string}',
		'query-input': 'required name=search_term_string',
	},
};

const productSchema = (product = {}, selectedVariant = {}, reviewSummary, currentUrl = '') => {
	const productCount = product.variants?.length || 0;
	const firstVariant = product.variants?.[0] || {};
	const lastVariant = product.variants?.[productCount - 1] || {};
	const { ratingAvg, reviewsCount } = reviewSummary || {};

	const _getPriceValid = () => {
		const dateString = new Date(+new Date() + 1.8e6);
		const offset = new Date().getTimezoneOffset();
		const myDate = Date.parse(dateString) - (offset * 60 * 1000);
		const dateAsISO = new Date(myDate).toISOString();

		return dateAsISO;
	};

	const _mapVariants = (variant = {}) => {
		const url = `${currentUrl}/${variant.slug}/p`;
		const availability = variant.inStock
			? 'https://schema.org/InStock'
			: 'https://schema.org/OutOfStock';
		const offers = {
			'@type': 'Offer',
			'priceCurrency': 'BRL',
			'price': variant.price,
			'sku': variant.sku,
			'priceValidUntil': _getPriceValid(),
			availability,
			url,
		};
		return offers;
	};
	const schema = {
		'@context': 'https://schema.org/',
		'@type': 'Product',
		'name': selectedVariant.name,
		'image': selectedVariant.images?.[0]?.productUrl,
		'description': product.description,
		'sku': product.sku,
		'brand': {
			'@type': 'Organization',
			'name': product.brand?.name,
			'url': `${currentUrl}${product.brand?.url}`,
		},
		'mpn': product.sku,
		'offers': {
			'@type': 'AggregateOffer',
			'priceCurrency': 'BRL',
			'highPrice': lastVariant.price,
			'lowPrice': firstVariant.price,
			'offerCount': productCount,
			'offers': product.variants?.map(_mapVariants) || [],
		},
	};
	if (ratingAvg > 0) {
		schema.aggregateRating = {
			'@type': 'AggregateRating',
			'ratingValue': ratingAvg,
			'reviewCount': reviewsCount,
		};
	}
	return schema;
};

const _mapReviews = (item = {}) => {
	return {
		'@type': 'Review',
		'author': {
			'@type': 'Person',
			'name': item.author.name,
		},
		'datePublished': item.insertedAt,
		'description': item.description,
		'name': item.title,
		'reviewRating': {
			'@type': 'Rating',
			'bestRating': '5',
			'ratingValue': item.rating || 5,
			'worstRating': '1',
		},
	};
};

const breadCrumbList = (breadCrumb = []) => {
	return {
		'@context': 'http://schema.org',
		'@type': 'BreadcrumbList',
		'itemListElement': breadCrumb.map((bc, bcIndex) => {
			return {
				'@type': 'ListItem',
				'position': bcIndex + 1,
				'name': bc.name,
				'item': bc.url,
			};
		}),
	};
};

const qaPage = (questions) => {
	if (!Array.isArray(questions) || !questions.length) {
		return '';
	}

	const firstQuestion = questions[0];

	if (!firstQuestion || !Array.isArray(firstQuestion.answers) || !firstQuestion.answers.length) {
		return '';
	}

	const { title, description, answers, votes } = firstQuestion;

	const suggestedAnswer = (questionAnswers) => {
		if (!Array.isArray(questionAnswers) || questionAnswers.length < 2) {
			return [];
		}

		const questionAnswersCopy = [...questionAnswers];
		questionAnswersCopy.shift();

		return questionAnswersCopy.map((answer) => {
			return {
				'@type': 'Answer',
				'text': answer.title,
				'upvoteCount': answer.votes.total,
			};
		});
	};

	return {
		'@context': 'https://schema.org',
		'@type': 'QAPage',
		'mainEntity': {
			'@type': 'Question',
			'name': title,
			'text': description ? description : '',
			'answerCount': answers.length,
			'upvoteCount': votes.total,
			'acceptedAnswer': {
				'@type': 'Answer',
				'text': answers[0].title,
				'upvoteCount': answers[0].votes.total,
			},
			'suggestedAnswer': suggestedAnswer(questions[0].answers),
		},
	};
};

const schemaOrganizations = {
	'@context': 'https://schema.org',
	'@type': 'Organization',
	'name': 'Petlove',
	'image': 'https://www.petlove.com.br/static/uploads/banner_image/image/44490/petlove_logo.png',
	'address': {
		'@type': 'PostalAddress',
		'streetAddress': 'Av. das Nações Unidas, 12901 - 36.º andar',
		'addressLocality': 'Brooklin Paulista',
		'addressRegion': 'SP',
		'postalCode': '04578-910',
		'addressCountry': 'BR',
	},
	'foundingDate': '1999-01-01',
	'telephone': '+55-11-3003-7190',
	'subOrganization': [
		{
			'@type': 'Organization',
			'name': 'Dog Hero',
			'image': 'https://theme.zdassets.com/theme_assets/699653/88347bdce0954509ab0f49316778c65118506e65.png',
			'address': {
				'@type': 'PostalAddress',
				'streetAddress': 'Av. das Nações Unidas, 12901 - 36.º andar',
				'addressLocality': 'Brooklin Paulista',
				'addressRegion': 'SP',
				'postalCode': '04578-910',
				'addressCountry': 'BR',
			},
			'foundingDate': '1999-01-01',
			'telephone': '+55-11-3003-7190',
		},
		{
			'@type': 'Organization',
			'name': 'Nofaro',
			'image': 'https://plano-de-saude.petlove.com.br/img/logo-petlove-primary.f6bf9a6a.svg',
			'address': {
				'@type': 'PostalAddress',
				'streetAddress': 'Av. das Nações Unidas, 12901 - 36.º andar',
				'addressLocality': 'Brooklin Paulista',
				'addressRegion': 'SP',
				'postalCode': '04578-910',
				'addressCountry': 'BR',
			},
			'foundingDate': '2015-01-01',
			'telephone': '+1-555-555-5555',
		},
		{
			'@type': 'Organization',
			'name': 'Porto.pet',
			'image': 'https://saude.petlove.com.br/images/logos/logo-petlove+porto.png',
			'address': {
				'@type': 'PostalAddress',
				'streetAddress': 'Av. das Nações Unidas, 12901 - 36.º andar',
				'addressLocality': 'Brooklin Paulista',
				'addressRegion': 'SP',
				'postalCode': '04578-910',
				'addressCountry': 'BR',
			},
			'foundingDate': '1999-01-01',
			'telephone': '+55-11-3003-7190',
		},
		{
			'@type': 'Organization',
			'name': 'Health for pet',
			'image': 'https://saude.petlove.com.br/images/logos/logo-petlove+porto.png',
			'address': {
				'@type': 'PostalAddress',
				'streetAddress': 'Av. das Nações Unidas, 12901 - 36.º andar',
				'addressLocality': 'Brooklin Paulista',
				'addressRegion': 'SP',
				'postalCode': '04578-910',
				'addressCountry': 'BR',
			},
			'foundingDate': '1999-01-01',
			'telephone': '+55-11-3003-7190',
		},
		{
			'@type': 'Organization',
			'name': 'Petlove Saúde',
			'image': 'https://saude.petlove.com.br/images/logos/logo-petlove+porto.png',
			'address': {
				'@type': 'PostalAddress',
				'streetAddress': 'Av. das Nações Unidas, 12901 - 36.º andar',
				'addressLocality': 'Brooklin Paulista',
				'addressRegion': 'SP',
				'postalCode': '04578-910',
				'addressCountry': 'BR',
			},
			'foundingDate': '1999-01-01',
			'telephone': '+55-11-3003-7190',
		},
		{
			'@type': 'Organization',
			'name': 'Vetsmart',
			'image': 'https://minhaloja.petlove.com.br/img/petlove-vetsmart.1a3664e9.png',
			'address': {
				'@type': 'PostalAddress',
				'streetAddress': 'Av. das Nações Unidas, 12901 - 36.º andar',
				'addressLocality': 'Brooklin Paulista',
				'addressRegion': 'SP',
				'postalCode': '04578-910',
				'addressCountry': 'BR',
			},
			'foundingDate': '1999-01-01',
			'telephone': '+55-11-3003-7190',
		},
		{
			'@type': 'Organization',
			'name': 'Vetus',
			'image': 'https://vetus.com.br/src/images/logo-vetus.svg',
			'address': {
				'@type': 'PostalAddress',
				'streetAddress': 'Av. das Nações Unidas, 12901 - 36.º andar',
				'addressLocality': 'Brooklin Paulista',
				'addressRegion': 'SP',
				'postalCode': '04578-910',
				'addressCountry': 'BR',
			},
			'foundingDate': '1999-01-01',
			'telephone': '+55-11-3003-7190',
		},
	],
};

export { title, description, canonical, manifest, metatags, open_graph, supported_media, link_icons, productSchema, _mapReviews, breadCrumbList, schemaOrganizations, qaPage };
