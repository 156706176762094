/**
 * @typedef PromotionDataEvent
 * @type {object}
 * @property {string} id - O ID único associado à promoção (exemplo: “3343594866”).
 * @property {string} creative - O nome criativo específico da promoção (exemplo: “saude-na-globo-nofaro@setor_nofaro”).
 * @property {string} creative - O tipo de criativo associado à promoção (exemplo: "desconto").
 * @property {string} position - A posição do criativo na promoção (exemplo: “carrossel”).
 * @property {string} target - O público-alvo ou segmento ao qual a promoção é direcionada (exemplo: "usuários premium").
 * @property {string} name - O nome do banner associado à promoção (exemplo: "banner_promocional_2024").
 * @property {string} id_ov - O ID associado à promoção na plataforma de publicidade online (exemplo: “ABC123”).
 * @property {string} banner_type - O tipo de banner usado na promoção (exemplo: "banner_horizontal").
 * @property {string} supplier - O fornecedor ou parceiro associado à promoção (exemplo: "ParceiroXYZ").
 */

/**
 * @param {('view'|'click')} type
 * @param {PromotionDataEvent[]} promotions
 * @param {string} position
 */
export const dispatchPromotionsEvent = (type, promotions, position) => {
  try {
    const event = {
      view: 'promotionImpression',
      click: 'promotionClick',
    }[type];
    const key = {
      view: 'promoView',
      click: 'promoClick',
    }[type];
    const nd = 'nao-disponivel';

    window.dataLayer?.push({
      event,
      ecommerce: {
        [key]: {
          promotions: promotions.map((promo) => {
            return {
              id: promo.id || nd,
              creative: promo.creative || nd,
              position: promo.position || position || nd,
              target: promo.target || nd,
              name: promo.name || nd,
              banner_type: promo.banner_type || nd,
              supplier: promo.supplier || nd,
            };
          }),
        },
      },
    });
  }
  catch (e) {
    console.error(`Error sending data to GA: ${e}`);
  }
};
