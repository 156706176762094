import { getCookie, setCookie } from '@petlove/lion/libs/cookies';
import { useAuthStore } from '../store/auth';

export function useDynamicYield() {
  const DY_CHOOSE_API_URL = 'https://direct.dy-api.com/v2';
  const DY_COLLECT_API_URL = 'https://direct-collect.dy-api.com/v2';

  const userData = computed(() => ({
    user: {
      dyid: getCookie('_dyid'),
      dyid_server: getCookie('_dyserver'),
    },
    session: { dy: getCookie('_dyjsession') },
  }));

  const setHeaders = () => {
    const config = useRuntimeConfig();
    return {
      'accept': 'application/json',
      'content-type': 'application/json',
      'DY-API-Key': config.public.DY_CLIENT_API_KEY || '',
    }
  }

  const _setDyCookies = (cookies) => {
    if (!Array.isArray(cookies)) return;

    const [dyServer, dySession] = cookies;
    dyServer?.value && setCookie('_dyid', dyServer.value, 365);
    dySession?.value && setCookie('_dyjsession', dySession.value, 0, 30);
  };

  const _reportEngagement = (type, id) => {
    try {
      if (!getCookie('_dyid') || !getCookie('_dyjsession')) return;
      const engagement
        = type === 'CLICK'
          ? { decisionId: id, type }
          : { slotId: id, type: 'SLOT_CLICK' };
      return $fetch(`${DY_COLLECT_API_URL}/collect/user/engagement`, {
        method: 'POST',
        body: {
          ...userData.value,
          engagements: [engagement],
        },
        headers: setHeaders(),
      });
    }
    catch (error) {
      console.error(`Error sending ${type} to DY:`, error);
    }
  };

  const getDYChoose = async ({
    experiment,
    pageType,
    sku,
    args,
    customAttributes = {},
  }) => {
    if (!experiment) throw new Error('Experiment is required');
    if (!pageType) throw new Error('PageType is required');

    const authStore = useAuthStore();
    const { isClubMember, subscriber, isNewClient, isLogged } = authStore;

    const campaign = Array.isArray(experiment) ? experiment : [experiment];

    const body = {
      ...userData.value,
      context: {
        page: {
          type: pageType,
          data: sku ? [sku] : [],
          location: window.location.href,
          referrer: document.referrer || '',
        },
        channel: 'web',
        pageAttributes: {
          pet_species: getCookie('PL_pet_species') || '',
          isSubscriber: subscriber ? 'true' : 'false',
          isClubMember: isClubMember ? 'true' : 'false',
          isNewUser: isNewClient || !isLogged ? 'true' : 'false',
          isLogged: isLogged ? 'true' : 'false',
          ...customAttributes,
        },
      },
      selector: { names: campaign, ...args },
      options: {
        isImplicitPageview: false,
        isImplicitImpressionMode: true,
        isImplicitClientData: true,
        returnAnalyticsMetadata: true
      },
    };

    const data = await $fetch(`${DY_CHOOSE_API_URL}/serve/user/choose`, {
      method: 'POST',
      body,
      headers: setHeaders(),
    });

    if (data?.cookies) {
      _setDyCookies(data.cookies);
    }

    return data;
  };

  const reportDYClick = (decisionId) => _reportEngagement('CLICK', decisionId);
  
  const reportDYSlotClick = (slotId) => _reportEngagement('SLOT_CLICK', slotId);

  const reportDYEvents = (events) => {
    try {
      if (!getCookie('_dyid') || !getCookie('_dyjsession')) return;
      return $fetch(`${DY_COLLECT_API_URL}/collect/user/event`, {
        method: 'POST',
        body: {
          ...userData.value,
          events,
        },
        headers: setHeaders()
      });
    }
    catch (error) {
      console.error('Error report event to DY:', error);
    }
  };

  return {
    getDYChoose,
    reportDYClick,
    reportDYSlotClick,
    reportDYEvents,
  };
}
